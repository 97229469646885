
<template>
  <footer class="comp-footer">
    <section data-section="Company">
        <span class="bold">Grafik-Design Studio Eureka!</span>
        <span>© {{ new Date().getFullYear() }}</span>
    </section>

    <section data-section="Opening Hours">
        <p class="section-nametag">Öffnungszeiten</p>
    
        <div class="decentralized">
            <!-- <p>
              Dienstag — Freitag  (Werktags)<br>
              09:00 — 15:00 Uhr
            </p> -->
            <p>
              Wir arbeiten dezentral und asynchron.
            </p>
        </div>
        
        <div>
            <p>Hinterlasse uns gerne eine Nachricht,
              wir melden uns binnen 1 bis 2 Werktagen.</p>
        </div>
    </section>

    <section data-section="Contact">
        <p class="section-nametag">Kontakt</p>
        
        <div>
          <a :href="`mailto:${computedMail}`"
          >{{ computedMail }}</a>
          
          <a :href="`tel:${computedPhone.number}`"
          >{{ computedPhone.layout }}</a>
        </div>
            
        <!-- <div>
          <a :href="`tel:${computedPhone.number}`"
          >Telefonat planen</a>
          <a :href="`tel:${computedPhone.number}`"
          >Meeting planen</a>
        </div> -->
    </section>

    <section data-section="Legal">
        <p class="section-nametag">Rechtliches</p>
        
        <div>
          <router-link to="/impressum">Impressum</router-link>
          <!-- <router-link to="/aab">Datenschutz</router-link> -->
          <router-link to="/aab">AAB</router-link>
        </div>
<!--         
        <div>
          <router-link to="/impressum">Barrierefreiheit</router-link>
        </div> -->
    </section>
  </footer>
</template>

<script>
export default {
  name: 'CompFooter',

  computed: {
    computedPhone () {
      return this.$store.state.phone
    },

    computedMail () {
      return this.$store.state.mail
    }
  },
}
</script>

<style lang="scss">
	// @import '../assets/css/variables.css';
  @import '../assets/css/scss-variables.scss';
  
  $mediaquery-footer-micro: 350px;
  
  .bold {
    font-weight: 800;
  }

	.comp-footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(180px, 100%), 1fr));
    column-gap: var(--column-gap);
    row-gap: calc(var(--spacer) * 1);
    
    margin-top: calc(var(--spacer) * 2);
    margin-bottom: var(--navigation-bar-height);
    margin-bottom: max(var(--navigation-bar-height), calc(var(--spacer) * 2));
    
    max-width: var(--max-width);
    margin-inline: auto;

    section:not([data-section="Company"], [data-section="Opening Hours"]), 
    section[data-section="Opening Hours"] .section-nametag {
      --line-height-footer: calc(var(--line-height) * 1.3);
      line-height: var(--line-height-footer);
    }
    
    section[data-section="Company"] {
      display: inherit;
      grid-template-columns: inherit;
      gap: inherit; 
      grid-column: 1 / -1;
      
      @media (width >= $mediaquery-tablet-portrait) {
        grid-column: unset;
        display: flex;
        gap: var(--_gap);
      }
    }
    
    section[data-section="Opening Hours"] {
      grid-column: 1 / -1;
      text-wrap: unset;
      
      p {
        max-width: 38ch;
      }
      
      @media (width >= $mediaquery-tablet-portrait) {
        grid-column: unset;
        display: flex;
        gap: var(--_gap);
      }
      
      @media (width >= $mediaquery-tablet-landscape) {
        grid-column: unset;
        display: flex;
        gap: var(--_gap);
        
        p {
          max-width: 28ch;
        }
      }
    }
    
    @media (pointer: fine) {
      margin-bottom: unset;
    }
    
    @media (width >= $mediaquery-footer-micro) {
      grid-template-columns: 1fr 1fr;
    }

    @media (width >= $mediaquery-tablet-landscape) {
      /* margin-bottom: unset; */
      /* grid-template-columns: repeat(auto-fit, minmax(min(180px, 100%), 1fr)); */
      
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      
      section {
        min-width: 14ch;
      }
    }

    a {
      line-height: inherit;
    }
    
    p {
      max-width: 28ch;
    }

    section {
      --_gap: 0.75em;
      display: flex;
      gap: var(--_gap);
      flex-direction: column;
      margin-top: unset;
      
      .section-nametag {
        font-weight: 600;
        opacity: var(--opacity-low);
        
        @media (width >= $mediaquery-tablet-portrait) {
          position: absolute;
          translate: 0 calc(-100% - var(--_gap));
        }
      }
      
      div {
        display: inherit;
        flex-direction: inherit;
      }
    }
  }
</style>
