<template>
  <main class="page page-imprint">
    <h1 class="typography-editorial-big">Impressum</h1>

    <div
      v-for="(infoblock, infoblockIndex) in computedInfoblocks"
      :key="`infoblock-${infoblockIndex}`"
      class="infoblock"
    >
      <div
        v-for="infoelement in infoblock"
        :key="`infoelement-${infoelement.notation}`"
        class="infoelement"
      >
        <p>{{ infoelement.notation }}:</p>

        <h6
          v-if="infoelement.type === `text`"
          class="infoelement-content"
        >
          {{ infoelement.content }}
        </h6>

        <div v-else-if="infoelement.type === `parapraphs`">
          <p
            v-for="(paragraph, paragraphIndex) in infoelement.content"
            :key="`paragraph-index-${paragraphIndex}`"
            class="infoelement-content"
          >
            {{ paragraph }}<span v-if="paragraphIndex + 1 !== infoelement.content.length">,</span>
          </p>
        </div>

        <a
          v-else-if="infoelement.type === `link`"
          :href="infoelement.link"
          :rel="infoelement.rel"
          class="infoelement-content"
        >
          {{ infoelement.content }}
        </a>
      </div>
    </div>

  <comp-footer />
  </main>
</template>

<script>
  import CompFooter from '../components/CompFooter.vue'

	import { createSEOMeta } from "@/helpers/helperMetainfo.js";

	const metaInfos = {
		title: `Impressum • Grafik-Design Studio Eureka!`,
		description: ``,
		image: ``,
		robots: `index, follow`
	}

  export default {
    components: {
      CompFooter
    },

    computed: {
      computedPhone () {
          return this.$store.state.phone
      },

      computedMail () {
          return this.$store.state.mail
      },

      computedDomainFull () {
          return this.$store.state.domain.full
      },

      computedDomainWritten () {
          return this.$store.state.domain.written
      },

      computedInfoblocks() {
        return [
            [
                {
                    type: `text`,
                    notation: `Name`,
                    content: `Mag.art. Mato Vincetić`
                },
                {
                    type: `text`,
                    notation: `Gewerbebezeichnung`,
                    content: `Werbegrafik-Designer`
                },
                {
                    type: `text`,
                    notation: `Unternehmensgegenstand`,
                    content: `Grafische Gestaltung & Werbung`
                }
            ],
            [
                {
                    type: `text`,
                    notation: `Standort der Gewerbeberechtigung`,
                    content: `Hermanngasse 9/1/R1, 1070 Wien, Österreich`
                },
                {
                    type: `link`,
                    notation: `Telefonnummer`,
                    content: this.computedPhone.layout,
                    link: `tel:${this.computedPhone.phone}`,
                    rel: ``
                },
                {
                    type: `link`,
                    notation: `E-Mailadresse`,
                    content: this.computedMail,
                    link: `mailto:${this.computedMail}`,
                    rel: ``
                },
                {
                    type: `link`,
                    notation: `Webseite`,
                    content: this.computedDomainWritten,
                    link: this.computedDomainFull,
                    rel: ``
                }
            ],
            [
                {
                    type: `parapraphs`,
                    notation: `Mitgliedschaften bei der Wirtschaftskammerorganisation`,
                    content: [
                      `Wirtschaftskammer Österreich (WKO)`,
                      `FG Werbung und Marktkommunikation`
                    ]
                },
                {
                    type: `text`,
                    notation: `Andere Mitgliedschaften`,
                    content: `Design Austria`
                },
            ],
            [
                {
                    type: `text`,
                    notation: `UID-Nummer`,
                    content: `ATU7 197 1903`
                },
            ],
            [
                {
                    type: `text`,
                    notation: `Bank`,
                    content: `Steiermärkische Sparkasse`
                },
                {
                    type: `text`,
                    notation: `IBAN`,
                    content: `AT11 2081 5000 4153 1070`
                },
                {
                    type: `text`,
                    notation: `BIC`,
                    content: `STSPAT2GXXX`
                },
            ],
            [
              {
                type: `link`,
                notation: `Anwendbare Rechtsvorschriften und Zugang dazu`,
                content: `Berufsrecht: Gewerbeordnung www.ris.bka.gv.at`,
                link: `https://www.ris.bka.gv.at`,
                rel: `noopener`
              },
              {
                type: `text`,
                notation: `Behörde gem. ECG (E-Commerce Gesetz)`,
                content: `Magistratisches Bezirksamt des XII. Bezirkes`
              },
              {
                type: `text`,
                notation: `Verleihungsstaat`,
                content: `Österreich`
              },
              {
                type: `text`,
                notation: `Angaben zur Online-Streitbeilegung`,
                content: `Verbraucher haben die Möglichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu richten: http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.`
              },
            ]
        ]
      }
    },

    metaInfo: createSEOMeta(metaInfos.title, metaInfos.description, metaInfos.image, metaInfos.robots)
  }

</script>

<style lang="scss">
  @import '../assets/css/variables.css';

  .page-imprint {

		& > * + *:not(.comp-footer) {
			margin-top: var(--spacer-padding);
		}

    .infoblock {
      max-width: 50ch;
      margin-inline: auto;
      text-align: center;

      .infoelement {

        & + .infoelement {
          margin-top: 1.5em;
        }

        .infoelement-content {
          text-transform: unset;
          font-size: var(--font-size-headline-bodytext);
          margin-top: .1em;
          line-height: 175%;
          font-weight: 900;
          color: inherit;
        }
      }
    }
  }
</style>
